<script setup lang="ts">
import { computed, type UnwrapNestedRefs } from 'vue';
import type { PwaInjection } from '@vite-pwa/nuxt/dist/runtime/plugins/types';
import type { User } from '~/types/user';

const { $pwa }: { $pwa: UnwrapNestedRefs<PwaInjection> } = useNuxtApp();

// TODO:: investigate if $pwa.offlineReady is a good fit for the agent portal app

const availableToInstall = computed(() => {
  return $pwa?.showInstallPrompt && !$pwa?.needRefresh;
});

const updateAvailable = computed(() => {
  return $pwa?.needRefresh;
});

const { user }: { user: User } = useAuth();

// Potential edge case fix for updateServiceWorker doing nothing
async function updatePWA() {
  await $pwa.updateServiceWorker();

  // if needRefresh still true reload the page
  if ($pwa?.needRefresh) {
    location.reload();
  }
}
</script>

<template>
  <div
    v-show="
      (availableToInstall || updateAvailable) && user?.impersonated !== true
    "
    role="alert"
    class="w-full justify-center px-4 py-2 text-center"
    :class="{
      'bg-red-600': updateAvailable,
      'bg-green-600': availableToInstall,
    }"
  >
    <div v-if="updateAvailable">
      <span class="text-base text-white">
        <FontAwesomeIcon :icon="['fas', 'triangle-exclamation']" />
        <span> We have updated the Letting agent portal. </span>
      </span>
      <button
        type="button"
        class="inline text-base font-semibold text-white underline"
        @click="updatePWA"
      >
        Update now
      </button>
      <!--      <span class="text-base text-white"> or </span>-->
      <!--      <button-->
      <!--        type="button"-->
      <!--        class="inline text-base font-semibold text-white underline"-->
      <!--        @click="$pwa.cancelPrompt()"-->
      <!--      >-->
      <!--        ignore update.-->
      <!--      </button>-->
    </div>
    <div v-if="availableToInstall" class="relative">
      <span class="text-base text-white">
        <FontAwesomeIcon :icon="['fas', 'circle-question']" />
        Did you know the Letting agent portal is available to install as an app?
      </span>
      <button
        type="button"
        class="inline text-base font-semibold text-white underline"
        @click="$pwa.install()"
      >
        Click here to get started.
      </button>
      <button
        type="button"
        class="absolute right-0 inline text-lg text-white"
        @click="$pwa.cancelInstall()"
      >
        <FontAwesomeIcon :icon="['fas', 'xmark']" />
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
